import { Utils } from '@/Utils';

$(document).on('click', '.requestAccessBtn', function () {
  const feature_name = $(this).data('feature');

  window.$.ajax({
    url: '/request-access',
    method: 'POST',
    data: {
      //current page url (without params)
      url: window.location.origin + window.location.pathname,
      feature_name: feature_name
    },
    success: (response) => {
      window.toast(response.message, 'success');
    },
    error: (error) => {
      let message = Utils.getStatusMessage(500, false);

      if (typeof error?.responseJSON?.message !== 'undefined') {
        message = error.responseJSON.message;
      }

      window.toast(message, 'error');
    }
  });
});
